import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6d16230c = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _65719ee2 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _7b818802 = () => interopDefault(import('../pages/account/details/index.vue' /* webpackChunkName: "pages/account/details/index" */))
const _6a1db3cc = () => interopDefault(import('../pages/account/newsletter.vue' /* webpackChunkName: "pages/account/newsletter" */))
const _04ce3f4c = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _45292b19 = () => interopDefault(import('../pages/account/details/login.vue' /* webpackChunkName: "pages/account/details/login" */))
const _ca90c14e = () => interopDefault(import('../pages/account/details/profile.vue' /* webpackChunkName: "pages/account/details/profile" */))
const _57fc6cf1 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9d25d7ee = () => interopDefault(import('../pages/figma-design-code/index.vue' /* webpackChunkName: "pages/figma-design-code/index" */))
const _6cfcd32a = () => interopDefault(import('../pages/icon.vue' /* webpackChunkName: "pages/icon" */))
const _7556708b = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _6b73dc6d = () => interopDefault(import('../pages/blog/category/index.vue' /* webpackChunkName: "pages/blog/category/index" */))
const _f5771932 = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _53ae7aab = () => interopDefault(import('../pages/blocks/demo.vue' /* webpackChunkName: "pages/blocks/demo" */))
const _2e06e164 = () => interopDefault(import('../pages/forgot-password/reset.vue' /* webpackChunkName: "pages/forgot-password/reset" */))
const _47a8bdb8 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _bed3e352 = () => interopDefault(import('../pages/forgot-password/redirect.vue' /* webpackChunkName: "pages/forgot-password/redirect" */))
const _324ba090 = () => interopDefault(import('../pages/blog/detail/_slug.vue' /* webpackChunkName: "pages/blog/detail/_slug" */))
const _07d8c20c = () => interopDefault(import('../pages/blog/category/_.vue' /* webpackChunkName: "pages/blog/category/_" */))
const _7d464f24 = () => interopDefault(import('../pages/blog/tag/_.vue' /* webpackChunkName: "pages/blog/tag/_" */))
const _0961117e = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _6d16230c,
    children: [{
      path: "",
      component: _65719ee2,
      name: "account___nl"
    }, {
      path: "details",
      component: _7b818802,
      name: "account-details___nl"
    }, {
      path: "newsletter",
      component: _6a1db3cc,
      name: "account-newsletter___nl"
    }, {
      path: "overview",
      component: _04ce3f4c,
      name: "account-overview___nl"
    }, {
      path: "details/login",
      component: _45292b19,
      name: "account-details-login___nl"
    }, {
      path: "details/profile",
      component: _ca90c14e,
      name: "account-details-profile___nl"
    }]
  }, {
    path: "/en",
    component: _57fc6cf1,
    name: "index___en"
  }, {
    path: "/figma-design-code",
    component: _9d25d7ee,
    name: "figma-design-code___nl"
  }, {
    path: "/icon",
    component: _6cfcd32a,
    name: "icon___nl"
  }, {
    path: "/login",
    component: _7556708b,
    name: "login___nl"
  }, {
    path: "/nieuws",
    component: _6b73dc6d,
    name: "blog-category___nl"
  }, {
    path: "/wachtwoord-vergeten",
    component: _f5771932,
    name: "forgot-password___nl"
  }, {
    path: "/blocks/demo",
    component: _53ae7aab,
    name: "blocks-demo___nl"
  }, {
    path: "/en/account",
    component: _6d16230c,
    children: [{
      path: "",
      component: _65719ee2,
      name: "account___en"
    }, {
      path: "details",
      component: _7b818802,
      name: "account-details___en"
    }, {
      path: "newsletter",
      component: _6a1db3cc,
      name: "account-newsletter___en"
    }, {
      path: "overview",
      component: _04ce3f4c,
      name: "account-overview___en"
    }, {
      path: "details/login",
      component: _45292b19,
      name: "account-details-login___en"
    }, {
      path: "details/profile",
      component: _ca90c14e,
      name: "account-details-profile___en"
    }]
  }, {
    path: "/en/figma-design-code",
    component: _9d25d7ee,
    name: "figma-design-code___en"
  }, {
    path: "/en/forgot-password",
    component: _f5771932,
    name: "forgot-password___en"
  }, {
    path: "/en/icon",
    component: _6cfcd32a,
    name: "icon___en"
  }, {
    path: "/en/login",
    component: _7556708b,
    name: "login___en"
  }, {
    path: "/en/news",
    component: _6b73dc6d,
    name: "blog-category___en"
  }, {
    path: "/forgot-password/reset",
    component: _2e06e164,
    name: "forgot-password-reset___nl"
  }, {
    path: "/klantenservice/faq",
    component: _47a8bdb8,
    name: "faq___nl"
  }, {
    path: "/en/blocks/demo",
    component: _53ae7aab,
    name: "blocks-demo___en"
  }, {
    path: "/en/customer-service/faq",
    component: _47a8bdb8,
    name: "faq___en"
  }, {
    path: "/en/forgot-password/reset",
    component: _2e06e164,
    name: "forgot-password-reset___en"
  }, {
    path: "/myaccount/resetpassword/redirect",
    component: _bed3e352,
    name: "forgot-password-redirect___nl"
  }, {
    path: "/en/myaccount/resetpassword/redirect",
    component: _bed3e352,
    name: "forgot-password-redirect___en"
  }, {
    path: "/en/news/detail/:slug?",
    component: _324ba090,
    name: "blog-detail-slug___en"
  }, {
    path: "/nieuws/detail/:slug?",
    component: _324ba090,
    name: "blog-detail-slug___nl"
  }, {
    path: "/en/news/*",
    component: _07d8c20c,
    name: "blog-category-all___en"
  }, {
    path: "/en/tag/*",
    component: _7d464f24,
    name: "blog-tag-all___en"
  }, {
    path: "/en/*",
    component: _0961117e,
    name: "all___en"
  }, {
    path: "/nieuws/*",
    component: _07d8c20c,
    name: "blog-category-all___nl"
  }, {
    path: "/tag/*",
    component: _7d464f24,
    name: "blog-tag-all___nl"
  }, {
    path: "/",
    component: _57fc6cf1,
    name: "index___nl"
  }, {
    path: "/*",
    component: _0961117e,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
